.tp-slider-exp-item {
  background-color: var(--tp-common-white);
  padding: 40px;
  border-radius: 1rem;
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  text-align: left;
  animation: tpFadeUp 1s ease-in-out 0s forwards;
  flex: 1;
  min-height: 200px;
  position: relative;
  min-width: calc(250px - 80px);
}

@keyframes tpFadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.tp-slider-exp-item__icon {
  height: 50px;
  width: 50px;
  position: relative;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 50px;
  margin-bottom: 20px;
}
.tp-exp-sm-title {
  font-weight: 600;
  font-size: max(20px, 1.5vw);
  line-height: 30px;
  color: var(--tp-common-black);
  padding-bottom: 40px;
}
.tp-link-green a::before {
  transition: 1s;
  background-color: var(--tp-common-green);
}

.tp-link a::before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: var(--tp-common-yellow);
  border-radius: 50%;
  transition: 0.3s;
  top: -8px;
  left: 10px;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;
}

.tp-link-blue a::before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: var(--tp-common-blue);
  border-radius: 50%;
  transition: 0.3s;
  top: -8px;
  left: 10px;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;
}

.tp-link-green a::before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: var(--tp-common-green);
  border-radius: 50%;
  transition: 0.3s;
  top: -8px;
  left: 10px;
  transform: scale(0.3);
  opacity: 0;
  visibility: hidden;
}
.tp-link {
  position: absolute;
  bottom: 40px;
}
.tp-link a svg {
  position: relative;
  z-index: 2;
  margin-right: 20px;
}
.tp-link a {
  color: rgb(177, 177, 177);
  position: relative;
  transition: 1s;
  display: flex;
  align-items: center;
  letter-spacing: 0.2rem;
}
.tp-link-green a::after {
  background-color: rgba(51, 204, 121, 0.15);
}
.tp-link-green a:hover span {
  transition: 0.3s;
  color: var(--tp-common-green);
}
.tp-link span {
  transition: 0.3s;
  font-weight: 600;
}
.tp-link-green a:hover span {
  color: var(--tp-common-green) !important;
}
.tp-link-blue a:hover span {
  color: var(--tp-common-blue) !important;
  transition: 0.3s;
}
.tp-link a:hover {
  color: var(--tp-common-yellow);
  transition: 0.3s !important;
}
.tp-slider-exp-item__icon.icon-bg-green {
  background-color: var(--tp-common-green);
}
.tp-slider-exp-item__icon.icon-bg-blue {
  background-color: var(--tp-common-blue);
}
.tp-link a::after {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  background-color: rgba(238, 208, 100, 0.15);
  border-radius: 50%;
  transition: 0.5s;
  margin-top: -2px;
  left: 10px;
}
.tp-link a:hover::before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}
#checkmark {
  width: 40px;
  height: 50px;
}
.tp-slider-exp-item__icon {
  transition: 1s;
}
