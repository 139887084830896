.pb-120,
.pt-120 {
  padding: 120px 0px;
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.ab-title-xs {
  font-weight: 600;
  font-size: max(25px, 2vw);
  letter-spacing: -0.01em;
  line-height: 1.2;
}
.tp-about-us-area {
  width: 80%;
  margin: 0 auto;
  display: flex;

  justify-content: space-between;
  gap: 2rem;
  padding: 120px 0px;
}
.breadcrumbHeader {
  display: flex;
  align-items: center;
}
.breadcrumbHeader a {
  color: black;
}
.tp-inner-list ul li {
  display: flex;
  align-items: center;
}
.tp-inner-list ul li svg {
  height: 1rem;
}
.tp-about-text {
  max-width: 800px;
  width: 46%;
}
.aboutImageContainer {
  gap: 1rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  width: 100%;
}

.aboutImageContainer img {
  object-fit: cover;
  width: 100%;
}
.aboutImages {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.about_3 {
  width: 100%;
}
.aboutImages img {
  grid-template-columns: 1fr;
  border-radius: 1rem;
  object-fit: cover;
}
.about_1 {
  width: 100%;
}
.tp-inner-list ul li {
  font-family: "Arimo";
  font-weight: bold;
  font-size: max(14px, 1vw);
  line-height: 26px;
  color: var(--tp-common-black);
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}
.tp-about-us-area p {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #6a6a74;
  padding-right: 40px;
  padding-bottom: 20px;
}
.aboutusText {
  font-size: max(14px, 1vw) !important;
}
@media only screen and (max-width: 1160px) {
  .tp-about-us-area {
    flex-direction: column-reverse;
  }
  .aboutImages {
    width: 100%;
  }
  .aboutImages img {
    max-height: 60vh;
  }
  .tp-about-text {
    max-width: unset;
    width: 100%;
  }
  .tp-about-text h2 {
    font-size: max(30px, 3vw);
  }
  .aboutusText {
    font-size: max(18px, 1.5vw) !important;
  }
  .tp-inner-list ul {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    padding: 0px;
  }
  .tp-inner-list ul li {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    padding: 0px;
    align-items: center;
  }
}
@media only screen and (max-width: 900px) {
  .tp-inner-list ul {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin-left: 0px;
    padding: 0px;
  }
  .aboutusText {
    padding-right: 0 !important;
  }
}
