.breadcrumb {
  padding-top: 300px;
  padding-bottom: 160px;
  background: url("../images/breadcurmb.jpg");
  width: 100%;
}
.breadcrumbContainer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 80%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.breadcrumbTitle {
  font-size: 60px;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: var(--tp-common-black);
}
.breadcrumbTitle h4 {
  display: flex;
  align-items: Center;
  justify-content: center;
}
.breadcrumbHeader a {
  color: black;
}
