.servicesContainerMain {
  margin: 5rem auto;
  width: 80%;
}

.servicesCards {
  display: flex;
  gap: 3rem;
  justify-content: center;
  width: 80%;
  align-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.tp-section-subtitle.tp-yellow-color::before {
  background-color: var(--tp-common-red);
}
.tp-section-subtitle.tp-yellow-color::after {
  background-color: var(--tp-common-red);
}
.tp-section-title {
  font-weight: 600;
  font-size: 60px;
  line-height: 1.2;
  margin-top: 0px;
  letter-spacing: -0.01em;
  margin-bottom: 5rem;
}

.tp-section-subtitle {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--tp-common-black-2);
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 10px 20px;
  margin-top: 3rem;
  margin-bottom: 0px;
  border-radius: 65px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  margin-bottom: 30px;
  animation: ease 1s tpFadeUp;
}
.tp-section-subtitle::before {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.tp-section-subtitle::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}
.servicesContainerMain {
  text-align: center;
}
.servicePageCardsContainer .servicesCards {
}
.breadcrumbHeader {
  display: flex;
  align-items: center;
}
.breadcrumbHeader a {
  color: black;
}
@media screen and (max-width: 992px) {
  .servicesContainerMain {
    width: 100%;
  }
}
