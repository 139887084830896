.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--main-color-red);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  margin: 100px auto;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
