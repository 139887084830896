.home {
  width: 100%;
}
.homeBg {
  background: url("../images/slider-grident-bg.png");
  padding-bottom: 100px;
  padding-top: 160px;
  background-repeat: no-repeat;
}
.home section {
  text-align: center;
}
.tp-section-subtitle {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--tp-common-black-2);
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 10px 20px;
  margin-top: 3rem;
  margin-bottom: 0px;
  border-radius: 65px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  margin-bottom: 30px;
  animation: ease 1s tpFadeUp;
}
.tp-section-subtitle::before {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.tp-section-subtitle::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}
.tp-slider-title {
  animation: tpFadeUp 1s ease-in-out 0s forwards;
  opacity: 0; /* Initially set to completely invisible */
  padding-bottom: 15px;
  font-weight: 600;
  font-size: max(40px, 4vw);
  width: 87%;
  margin: 0 auto;
  animation-delay: 0.5s;
  line-height: 1.3;
  margin-top: 1rem;
  margin-bottom: 0px;
  letter-spacing: -0.01em;
}

.home section p {
  font-size: max(18px, 1vw);
  line-height: 32px;
  width: 87%;
  margin:1rem auto;
  color: var(--tp-common-black-2);
  padding-bottom: 30px;
  animation: tpFadeUp 1s ease-in-out 0s forwards;
}
.home .btnContainerHome {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
@keyframes tpFadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.serviceCardsContainer {
  display: flex;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  width: 80%;
  margin: 5rem auto;
  margin-bottom: 0px;
  flex-wrap: wrap;
}
.serviceCardsContainer:hover .tp-slider-exp-item__icon {
  height: 100px;
  transform: scale(1.2); /* Example: increase size by 20% */
}

.servicesCards {
  display: flex;
  gap: 3rem;
  justify-content: center;
  width: 80%;
  align-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.tp-section-subtitle.tp-yellow-color::before {
  background-color: var(--tp-common-red);
}
.tp-section-subtitle.tp-yellow-color::after {
  background-color: var(--tp-common-red);
}

.services_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 200px);
  padding: 100px 0px;
}
.tp-section-title {
  font-weight: 600;
  font-size: max(37px, 4vw);
  line-height: 1.2;
  margin-top: 0px;
  width: 80%;
  letter-spacing: -0.01em;
  margin-bottom: 5rem;
}
svg {
  width: 3rem;
  color: black;
  aspect-ratio: 1/1;
}
.portfolio_home {
  min-height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  padding: 100px 0px;
  flex-direction: column;
  background: url("../images/case-grident-shape.png") no-repeat;
  background-position: bottom;
}
.portfolio_home_container {
  width: 80%;
  margin: 0 auto;
}
.works {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.grid {
  display: flex;
  width: 80%;
  margin: 0 auto;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}
.grid a {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  min-width: 360px;
  overflow: hidden;
  align-items: center;
}
.works article a {
  width: 100%;
  height: 340px;
}
.works article img {
  width: 100%;
  border-radius: 0.5rem;
  height: 340px;
  object-fit: cover;
}

.grid article a {
  width: 100%;
  height: 100%;
}
.grid article img {
  width: 100%;
  border-radius: 0.5rem;
  height: 400px;
  object-fit: cover;
}
@media only screen and (max-width: 980px) {
  .grid article img {
    height: 100% !important;
  }
  .btnContainerHome {
    width: 80%;
    margin: 0 auto;
  }
  .btnContainerHome button {
    width: 100%;
    height: 50px;
    line-height: unset;
    font-size: max(14px, 1vw) !important;
  }
}
@media only screen and (max-width: 460px) {
  .works {
    flex-direction: column;
  }
  .worksLink {
    min-width: unset;
    height: 250px;
  }
  .tp-slider-title {
    font-size: max(25px, 4vw);
  }
  .homeBg p {
    width: 80%;
    margin: 0 auto;
  }
  .services_container {
    padding-top: 0px;
  }
}
