ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer {
  background: linear-gradient(165deg, #353535, #272649);
  padding-top: 80px;
  width: 80%;
  margin: 0rem auto 3rem auto;
  border-radius: 1rem 1rem 1rem 1rem;
  min-height: 30vh;
  padding-bottom: 40px;
}
.footer-container {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  color: white;
  height: 100%;
}
.footer-container ul li {
  margin: 1rem 0rem;
}
.footer-container img {
  max-width: 146px;
  width: 100%;
}

@media only screen and (max-width: 1065px) {
  .footer-container {
    flex-wrap: wrap;
    gap: 5rem;
    text-align: center;
    flex-direction: column;
  }
  .footer-container .row h3 {
    font-size: 1.7rem;
  }
}
@media only screen and (max-width: 1000px) {
  .footer {
    background: linear-gradient(165deg, #353535, #272649);
    padding-top: 80px;
    width: 100%;
    margin: 0rem auto 0rem auto;
    border-radius: 1rem 1rem 0px 0px;
    min-height: 30vh;
    padding-bottom: 40px;
  }
}
