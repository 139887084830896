.singleProjectContainer {
  margin: 100px auto;
  width: 80%;
}
.singleProjectContainer h1 {
  font-size: 3rem;
}
.singleProjectThumbnail {
  width: 100%;
  border-radius: 1rem;
}
.thumbnailFlex {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.thumbnailDescription {
  border: 1px solid #eee;
  flex: 1 0 auto;
  border-radius: 1rem;
  padding: 1rem 2rem;
  width: calc(50% - 10rem);
}
.thumbnailDescription p {
  border-bottom: 1px solid #eee;
  padding: 0rem 0rem 1rem 0rem;
}

.breadcrumbHeader a{
  color:black;
  
}
.breadcrumbHeader{
  display:Flex;
  align-items:Center;
  
}
.hashtags {
  color: var(--bs-blue);
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
  font-weight: 600;
}
.thumbnailDescription p:last-of-type {
  border-bottom: 0px;
}
.projectImages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  margin-bottom: 5rem;
}

.projectImages img {
  flex: 1 0;
  width: calc(50% - 4rem);
  border-radius: 1rem;
  /* object-fit: contain; */
  /* height: auto !important; */
  min-width: 400px;
  box-shadow: 23px 21px 26px -14px rgb(0 0 0 / 15%);
  object-fit: cover;
  height:100% !important;
  object-position: top;
}
.link a {
  color: var(--bs-blue);
}
.singleProjectDescription {
  margin: 5rem 0rem;
  font-size: 1.1rem;
  font-weight: 500;
  border: 1px solid #eee;
  padding: 2rem;
  border-radius: 1rem;
  opacity: 0.9;
}
.hashtags {
  display: flex;
  gap: 0.5rem;
}

.tp-section-subtitle {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: var(--tp-common-black-2);
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 10px 20px;
  margin-top: 3rem;
  margin-bottom: 0px;
  border-radius: 65px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  margin-bottom: 3rem;
  animation: ease 1s tpFadeUp;
}
.tp-section-subtitle::before {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.tp-section-subtitle::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}
.tp-slider-title {
  animation: tpFadeUp 1s ease-in-out 0s forwards;
  opacity: 0; /* Initially set to completely invisible */
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 85px;
  animation-delay: 0.5s;
  line-height: 1.1;
  margin-top: 1rem;
  margin-bottom: 0px;
  letter-spacing: -0.01em;
}

.thumbnailImageContainer {
  flex: 1 0 50%;
}
.thumbnailImageContainer img {
  height:100%;
  object-fit: cover;
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
}
.projectVideos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  gap: 2rem;
}
.projectVideos video {
  flex: 1 0 auto;
  max-height: 100vh;
  object-fit: cover;
  max-width: calc(33% - 2rem);
  border-radius: 1rem;
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
}
@media only screen and (max-width: 968px) {
  .thumbnailFlex {
    flex-direction: column;
  }
  .thumbnailDescription {
    width: unset;
  }
}
@media only screen and (max-width: 500px) {

  .projectImages img {
    width:100% !important;
    min-width:unset !important;
  }

}
