.categoryBtn {
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  font-weight: 600;
  color: #3f3f3f;
  font-size: 1rem;
  transition: 0.3s;
  letter-spacing: 1px;
}
.category {
  margin: 0rem auto 2rem auto;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}
.carouselCategory {
  width: 80%;

  overflow: hidden;
}
.breadcrumbHeader {
  display: flex;
  align-items: center;
}
.breadcrumbHeader a {
  color: black !important;
}
.worksLink {
  width: 95%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  border-radius: 2rem;
  min-width: 340px;
  border: 0.5rem solid white;
  flex: 1 0 calc(25% - 2rem);
}
.worksLink img {
  height: 340px;
  object-fit: cover;
  width: 100%;
  position: relative;
}
.active {
  color: #2795f5;
  background: var(--main-color-red);
}
.noproducts{
  text-align: center;
  min-height:20vh;
  display:flex;
  justify-content:center; 
   align-items: center;
   margin:0 auto;

}
.categoryBtn:hover {
  color: #2795f5;
  border: none;
  transition: all 0.4s cubic-bezier(0.43, 0, 0.28, 0.68);
  background: var(--main-color-red);
}
@media only screen and (max-width: 980px) {
  .categoryBtn {
    min-width: 10rem !important;
  }
  .category {
    justify-content: unset;
  }
  .worksLink a {
    min-width: unset;
  }
  .worksLink {
    height: 205px !important;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 460px) {
  .works {
    flex-direction: column;
  }
  .worksLink {
    min-width: unset;
    height: 312px;
  }
}
