@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");
html,body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Arimo", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --tp-ff-body: "Arimo", sans-serif;
  --tp-ff-heading: "Poppins", sans-serif;
  --tp-ff-fontawesome: Font Awesome 5 Pro;
  --tp-common-white: #ffffff;
  --main-color-red: #21353d;
  --tp-common-white-2: #f3f3f3;
  --tp-common-white-3: #f5f5f5;
  --tp-common-black: #151718;
  --tp-common-black-2: #52525c;
  --tp-common-black-3: #1f2021;
  --tp-common-yellow: #eed064;
  --tp-common-yellow-2: #fdfaef;
  --tp-common-green: #33cc79;
  --tp-common-green-2: #ebfaf2;
  --tp-common-blue: #eeeefd;
  --tp-common-orange: #ff7425;
  --tp-common-red: #21353d;
  --tp-common-sky: #1edbdc;
  --tp-common-deep-blue: #4c40f7;
  --tp-heading-primary: #151718;
  --tp-grey-1: #f0f0f0;
  --tp-grey-2: #f9f9f9;
  --tp-grey-3: #c2c2c2;
  --tp-grey-4: #dddddd;
  --tp-grey-5: #888888;
  --tp-grey-6: #f7f7f7;
  --tp-grey-7: #f6f6f6;
  --tp-text-body: #6a6a74;
  --tp-text-p: #6a6a74;
  --tp-theme-1: #000;
  --tp-border-1: #f5f5f5;
  --tp-border-2: #f4f4f4;
  --tp-border-3: #edefeb;
  --tp-border-4: #f1f3f6;
  --tp-border-5: #333435;
  --tp-border-6: #38393a;
  --tp-border-7: #ccd5da;
  --e-a-color-white: #fff;
  --e-a-color-black: #000;
  --e-a-color-logo: #fff;
  --e-a-color-primary: #f3bafd;
  --e-a-color-primary-bold: #d004d4;
  --e-a-color-secondary: #515962;
  --e-a-color-success: #0a875a;
  --e-a-color-danger: #dc2626;
  --e-a-color-info: #2563eb;
  --e-a-color-warning: #f59e0b;
  --e-a-color-accent: #93003f;
  --e-a-color-global: #1dddbf;
  --e-a-bg-default: #fff;
  --e-a-bg-invert: #0c0d0e;
  --e-a-bg-hover: #f1f2f3;
  --e-a-bg-active: #e6e8ea;
  --e-a-bg-active-bold: #d5d8dc;
  --e-a-bg-loading: #f9fafa;
  --e-a-bg-logo: #000;
  --e-a-bg-primary: #fae8ff;
  --e-a-bg-secondary: #515962;
  --e-a-bg-success: #f2fdf5;
  --e-a-bg-info: #f0f7ff;
  --e-a-bg-danger: #fef1f4;
  --e-a-bg-warning: #fffbeb;
  --e-a-color-txt: #515962;
  --e-a-color-txt-muted: #818a96;
  --e-a-color-txt-disabled: #babfc5;
  --e-a-color-txt-invert: #fff;
  --e-a-color-txt-accent: #0c0d0e;
  --e-a-color-txt-hover: #3f444b;
  --e-a-color-txt-active: #0c0d0e;
  --e-a-border-color: #e6e8ea;
  --e-a-border-color-bold: #d5d8dc;
  --e-a-border-color-focus: #babfc5;
  --e-a-border-color-accent: #0c0d0e;
  --e-a-border: 1px solid var(--e-a-border-color);
  --e-a-border-bold: 1px solid var(--e-a-border-color-bold);
  --e-a-border-radius: 3px;
  --e-a-btn-color: #0c0d0e;
  --e-a-btn-color-invert: var(--e-a-color-txt-invert);
  --e-a-btn-color-disabled: var(--e-a-color-txt-disabled);
  --e-a-btn-bg: #515962;
  --e-a-btn-bg-hover: #3a4046;
  --e-a-btn-bg-active: #23262a;
  --e-a-btn-bg-disabled: #d5d8dc;
  --e-a-btn-bg-primary: #f3bafd;
  --e-a-btn-bg-primary-hover: #f5d0fe;
  --e-a-btn-bg-primary-active: #f0abfc;
  --e-a-btn-bg-accent: #93003f;
  --e-a-btn-bg-accent-hover: #8f1a4c;
  --e-a-btn-bg-accent-active: #a93365;
  --e-a-btn-bg-info: #2563eb;
  --e-a-btn-bg-info-hover: #134cca;
  --e-a-btn-bg-info-active: #0e3b9c;
  --e-a-btn-bg-success: #0a875a;
  --e-a-btn-bg-success-hover: #06583a;
  --e-a-btn-bg-success-active: #03281b;
  --e-a-btn-bg-warning: #f59e0b;
  --e-a-btn-bg-warning-hover: #c57f08;
  --e-a-btn-bg-warning-active: #945f06;
  --e-a-btn-bg-danger: #dc2626;
  --e-a-btn-bg-danger-hover: #b21d1d;
  --e-a-btn-bg-danger-active: #861616;
  --e-a-dark-bg: #0c0d0e;
  --e-a-dark-color-txt: #9da5ae;
  --e-a-dark-color-txt-hover: #d5d8dc;
  --e-a-font-family: Roboto, Arial, Helvetica, sans-serif;
  --e-a-transition-hover: all 0.3s;
  --e-a-popover-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  --e-a-dropdown-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}
button {
  cursor: pointer;
  border: none;
}
a {
  text-decoration: none;
  color: white;
}
.rc-anchor-normal {
  height: 74px;
  width: 72%;
  margin: 0 auto;
  max-width: 300px;
}
