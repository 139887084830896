@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,300,500);

.button {
  font-weight: 300;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 1px solid var(--main-color-red);
  padding: 0.5em;
  border-radius: 3px;
  float: left;
  margin: 0 0 0 -155px;
  left: 50%;
  position: relative;
  background: var(--main-color-red);
  transition: all 0.3s linear;
}

.button:hover {
  background-color: #f32e70;
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.error404_paragraph {
  font-size: 2em;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: var(--main-color-red);
}

.fourtyfour {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15em;
  color: var(--main-color-red);
  font-weight: 100;
}
