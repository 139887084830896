.tp-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-green);
  margin-top: -10px;
  box-shadow: 0px 9px 14px 2px rgba(51, 204, 121, 0.8) !important;
  transition: 0.5s;
}
.tp-btn:nth-child(2):hover {
  background: rgb(58, 194, 228);
  box-shadow: 0px 9px 14px 2px rgba(58, 194, 228, 0.8) !important;
}
.tp-btn {
  display: inline-block;
  color: var(--tp-common-white);
  background: var(--tp-theme-1);
  border-radius: 10px;
  height: 70px;
  line-height: 70px;
  transform: translateY(0px) !important;
  text-align: center;
  padding: 0 2rem;
  position: relative;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  transition: 0.5s !important;
  font-size: max(16px, 1vw);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  box-shadow: 0px 9px 14px rgba(89, 86, 233, 0.19) !important;
  animation: tpFadeUp 1s ease-in-out 0s forwards;
  animation-delay: 0.5s;
}
.tp-show-more:hover {
  background: rgb(255, 74, 119);
}
.tp-show-more {
  display: inline-block;
  color: var(--tp-common-white);
  background: black;
  border-radius: 10px;
  height: 50px;
  text-align: center;
  padding: 0 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  text-transform: uppercase;
  transition: 0.3s;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 5rem;
  box-shadow: 0px 9px 14px rgba(144, 142, 255, 0.19) !important;
  animation: tpFadeUp 1s ease-in-out 0s forwards;
  animation-delay: 0.5s;
}
@keyframes tpFadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
