.contactModal {
  width: calc(80% - 10rem);
  padding: 5rem;
  border-radius: 1rem;
  background: white;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 10px 10px 60px rgba(0 0 0 / 18%);
  gap: 3rem;
}
.contactModalContainer {
  padding: 10vh 0vh;
  min-height: 80vh;
  background: linear-gradient(185deg, #f1f1f1, transparent);
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 1rem;
}
.formContactModal {
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 1rem;
  margin: 0 auto;
  flex-direction: column;
  flex: 1 0 auto;
}
.contactModalImage {
  position: absolute;
  right: -5rem;
  top: -5rem;
}
.formContactModal h4 {
  font-size: 1.5rem;
}
.formContactModal textarea,
.formContactModal input {
  width: 100%;
  padding: 1rem !important;
}
.formContactModal input:focus,
.formContactModal select:focus,
.formContactModal textarea:focus {
  box-shadow: 0px 1px 23px 3px rgb(228 12 90 / 18%);
  border: 2px solid rgba(228, 12, 91, 0.192);
}
.formContactModal input,
.formContactModal select,
.formContactModal textarea {
  padding: 1rem;
  width: calc(100% - 2rem);
  transition: 0.3s;
  font-size: 0.9rem;

  border-radius: 0.5rem;
  outline: none;
  background: rgb(247, 246, 246);
  border: 2px solid transparent;
}
.formContactModal select {
  width: 100%;
}
.formContactModal input[type="submit"] {
  cursor: pointer;
  background: black;
  color: white;
  font-size: 600;
  font-size: 1.3rem;
  width: 100% !important;
}
.formContactModal input[type="submit"]:disabled {
  background: #eee;
  color: rgb(192, 192, 192);
  cursor: not-allowed;
}
.formContactModal input[type="submit"]:disabled:hover {
  background: #eee;
}
.formContactModal input[type="submit"]:hover {
  background: #e40c5a;
}
.formContactModal textarea {
  min-height: 10vh;
}
.tp-section-title-xs {
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  letter-spacing: -0.01em;
  line-height: 1.2;
  margin-bottom: 0;
}
.contactModalInfo {
  flex: 1 0 auto;
  width: 100%;
}
.tp-section-subtitle {
  font-family: "Arimo";
  font-weight: 700;
  font-size: 16px;
  margin: 1.33em;
  line-height: 18px;
  color: var(--tp-common-black-2);
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 0px;
  border-radius: 65px;
  border: 1px solid var(--tp-border-1);
  box-shadow: 0px 9px 15px rgba(7, 18, 35, 0.08);
  margin-bottom: 30px;
  animation: ease 1s tpFadeUp;
}
.tp-section-subtitle::before {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
.tp-section-subtitle::after {
  content: "";
  height: 12px;
  width: 12px;
  background-color: var(--tp-theme-1);
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}
.contactList {
  gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  cursor: pointer;
}
.contactItem:hover p {
  color: white !important;
}
.contactItem:hover svg {
  color: white !important;
}

textarea {
  resize: none;
}

.contactItem p {
  margin: 0px;
  transition: 0.5s;
  font-size: 0.9rem !important;
  padding: 0px !important;
}
.contactItem {
  color: var(--main-color-black);
}
.contactList .contactItem {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  min-height: 55px;
  font-weight: 600;
  flex: 1 0 auto;
  border-radius: 1rem;

  border: 2px solid #f7f7f7;
  transition: 0.5s;
}
.recaptcha iframe {
  margin: 0 auto;
}
.breadcrumbHeader {
  display: flex;
  align-items: center;
}
.breadcrumbHeader a {
  color: black;
}
.contactItem:hover {
  background: var(--main-color-red);
}
.contactItem:hover .contactItem p,
.contactItem:hover .contactItem svg {
  color: white;
}
.contactItem svg {
  transition: 0.5s;

  height: 30px !important;
}
.contactList .contactItem:nth-child(3) svg {
  color: var(--tp-common-yellow);
}
.contactList .contactItem:nth-child(1) svg {
  color: var(--tp-common-red);
}
.contactList .contactItem:nth-child(2) svg {
  color: var(--tp-common-green);
}

.inputFlex {
  display: flex;
  gap: 1rem;
  width: 100%;
}
.contactModalContainer {
}
@media only screen and (max-width: 1165px) {
  .tp-section-title-xs {
    text-align: center;
    margin: 0px;
  }
  .contactItem p {
    font-size: max(14px, 1vw) !important;
  }
  .contactItem svg {
    height: 2vw !important;
  }
  .contactList {
    flex-direction: row;
  }
}
@media only screen and (max-width: 1065px) {
  .contactList {
    flex-direction: column;
  }
  .contactItem svg {
    height: 30px !important;
  }
  .formContactModal {
    width: 100%;
  }
}
@media only screen and (max-width: 700px) {
  .inputFlex {
    flex-wrap: wrap;
  }
  .contactModal {
    padding: 1rem;
    border-radius: 1rem;
    background: white;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    box-shadow: 10px 10px 60px rgba(0 0 0 / 18%);
    gap: 3rem;
  }
}

@media only screen and (max-width: 600px) {
  .contactItem {
    flex-direction: column;
    justify-content: center  !important;
    align-items: center !important;
  }
  .contactModal {
    width: calc(100% - 2rem);
  }
  .contactModalContainer {
    background-color: white;
  }
  .tp-section-subtitle {
    width: auto;
    margin: 2rem 0;
    z-index: 10;
    position: relative;
  }
  .tp-section-title-xs {
    font-size: 1.5rem;
  }
}
.ErrorInput {
  border: 2px solid #e40c5a;
}
.errorMessage {
  width: calc(100% - 2rem);
  background: #ff5a97;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid #e40c5a;
  border-radius: 0.5rem;
  text-align: left;
  margin-bottom: 0px;
  color: var(--main-color-black);
}
.ErrorInput {
  border: 2px solid #e40c5a !important;
}
