.partners {
  display: flex;
  margin: 0 auto;
  width: 80%;
  gap: 3rem;
  opacity: 1 !important;
  flex-wrap: wrap;
  align-items: center;
  mix-blend-mode: multiply;
  margin: 5rem auto;
  justify-content: center;
}
.partners img {
  width: 200px;
  object-fit: contain;
  max-height: 150px;
  mix-blend-mode: multiply;
}
