.Cards {
  padding: 30px;
  width: calc(20% - 60px);
  flex: 1 0 auto;
  min-width: 200px;
  border-radius: 1rem;
  box-shadow: 2px 20px 20px 10px rgba(233, 233, 233, 0.3);
  background: white;
  display: flex;
  align-items: center;
  gap: 0rem;
  justify-content: center;
  transition: 0.2s;
  flex-direction: column;
}
.Cards:hover {
  transform: scale(1.03);
  background: var(--tp-common-red);
  color: white;
}
.Cards:hover p {
  color: white;
}
.Cards p {
  transition: 0.2s;
  font-size: max(14px, 1vw) !important;
  margin: 0px;
}
.Cards h3 {
  font-size: max(22px, 1vw);
}
.Cards svg {
  min-height: 60px;
  height: 60px;
}
.Cards:hover svg {
  fill: white;
}
.cardMinWidth {
  min-height: 250px;
}
