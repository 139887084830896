.mainNavigation {
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  display: flex;
  position: absolute;
  width: 80%;
  top: 3rem;
  z-index: 100;
  left: 50%;
  transform: translate(-50%);
}
.mainNavigation ul {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}
.mainNavigation ul li {
  list-style-type: none;
}
.mainNavigation ul li a {
  text-decoration: none;
  color: #74757d;
  font-weight: 700;
  font-size: 1vw;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  padding: 50px 0px;
  display: inline-block;
  transition: 0.3s;
}
.mainNavigation ul li a:hover {
  color: black;
}
.active {
  color: #292929 !important;
}
.logo {
  width: 200px;
}
.menu {
  height: 2rem;
  cursor: pointer;
  color: #74757d;
}

@media only screen and (max-width: 1200px) {
  .mainNavigation ul li {
    display: none;
  }
}
