.sidebar {
  width: 0px;
  transition: 0.5s;
  height: 100%;
  right: 0px;
  top: 0px;
  position: fixed;
  overflow: hidden;
  z-index: 1001;
}
.sidebarActive {
  right: 0px;
  top: 0px;
  width: 20rem;
  background: rgb(32, 32, 32);
  position: fixed;
  z-index: 100;
  height: 100%;
  overflow: hidden;
  padding: 0rem 3rem;
  transition: 0.5s;
  color: white;
}
.sidebarHeader {
  margin-top: 1.5rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-left: 2rem;
}

.sidebarHeader .logo {
  margin-top: 3rem;
}
.closeSidebar {
  height: 2rem;
  color: white;
  right: 3rem;
  cursor: pointer;
  top: 3rem;
}
.sidebarOverlay {
  opacity: 0;
  transition: 0.5s;
  height: 100%;
}
.sidebarOverlayActive {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  top: 0px;
  transition: 0.5s;
  left: 0px;
  z-index: 100;
}
.logo {
  width:100px;
  margin:0 auto;
}
.sidebarContact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1.5rem;
  width: 100%;
  gap: 2rem;
  position: absolute;
  align-items: center;
}
.sidebarPosition {
  position: relative;
}
.sidebarContactContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sidebarInfo {
  text-align: right;
}
.sidebarContactContainer svg {
  color: white !important;
  height: 2rem;
}
.sidebarContact h3 {
  margin-top: 0px;
  margin-bottom: 0.5rem;
}
.sidebarInfo h3,
.sidebarInfo p {
  margin: 0px;
  transition: 0.3s;
}
.sidebarInfo p:hover,
.sidebarinfo a:hover {
  color: var(--tp-common-red);
}
.sidebarHr {
  width: 100%;
  color: #5a5a5a;
  background: #5a5a5a;
}
.sidebarNavigation ul {
  display: none;
  flex-direction: column;
  gap: 2rem;
  margin: 1.5rem 0rem;
}
.sidebarNavigation ul li {
  border-bottom: 2px solid #5a5a5a;
  padding-bottom: 0.5rem;
}
.active {
  font-weight: bold;
  color: #2795f5  !important;
}
.sidebarNavigation ul li:last-of-type {
  border-bottom: 0px;
}
hr {
  border: 0.5px solid #5a5a5a;
}
.responsiveHr {
  display: none;
}

.rights {
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .sidebarNavigation ul {
    display: flex;
  }
  .responsiveHr {
    display: block;
  }
}

@media screen and (max-width: 992px) {
  .sidebarActive {
    width: calc(100% - 6rem);
  }
  .sidebarHeader .logo {
    margin-top: 0rem;
  }
  .sidebarContactContainer {
    font-size: 0.8rem;
  }
  .sidebarContactContainer svg {
    width: 1.8rem;
    height: 1.8rem;
  }
  .sidebarHeader {
    justify-content: center;
    position: relative;
  }
  .sidebarNavigation ul {
    gap: 1rem;
    margin: 0.8rem 0;
  }
  .rights {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    justify-content: center;
    font-size: 0.8rem;
  }
  .closeSidebar {
    position: absolute;
    height: 1.5rem;
    color: white;
    right: -2.5rem;
    cursor: pointer;
    top: -0.5rem;
    width: 1.5;
    margin-left: 1.4rem;
  }
  .sidebarContact {
    gap: 1rem;
  }
}
